import { useState } from 'react';
import constate from 'constate';

function useStripeSecretContext() {
  const [stripeSecret, setStripeSecret] = useState('');
  return {
    stripeSecret,
    setStripeSecret,
  };
}

export const [StripeSecretProvider, useStripeSecret] = constate(
  useStripeSecretContext,
  value => value
);
