import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Link from 'components/router/Link';

const thisyear = new Date().getFullYear();

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.yellow,
    backgroundImage: 'url(/images/footer/Screen_1920_black.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '40px 0 20px',
    [theme.breakpoints.up('md')]: {
      height: '300px',
      padding: '0',
    },
  },
  innerContainer: {
    marginBottom: '25px',
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row',
    },
  },
  spacer: {
    width: '30px',
  },
  verticalLine: {
    borderLeft: '1px solid',
    borderColor: theme.palette.yellow,
    width: '1px',
    margin: '0 5px 0',
  },
  legalSection: {
    marginTop: '20px',
  },
  lawyerLinks: {
    textDecoration: 'underline',
  },
  linkButtons: {
    minWidth: 'fit-content',
    padding: '5px 15px',
    margin: '0 auto 10px',
    display: 'flex',
    color: theme.palette.yellow,
    justifyContent: 'center',
    border: `${theme.palette.yellow} 1px solid`,
    maxWidth: '300px',
    '&.yellow': {
      color: theme.palette.black,
      backgroundColor: theme.palette.yellow,
      border: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  sticker: {
    width: '100px',
    position: 'relative',
    top: '-30px',
    left: '30px',
    transform: 'rotate(-12.5deg)',
  },
  howToHelp: {},
  caption: {
    width: '300px',
  },
  socialGroup: {
    padding: '35px 0 10px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      top: '30px',
      padding: '0',
    },
  },
  socialIcon: {
    // backgroundColor: theme.palette.yellow,
    width: '20px',
    height: '20px',
    marginLeft: '10px',
  },
  socialText: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileOnly: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopOnly: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

const Footer = ({ className }) => {
  const router = useRouter();
  const classes = useStyles();
  return (
    <>
      <Grid container className={clsx(classes.container, className)}>
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          xs={10}
          className={classes.innerContainer}
          elevation={0}
        >
          <div>
            <Grid item xs={12}>
              <Typography className={classes.howToHelp} variant="h1">
                HOW TO HELP
              </Typography>
              <img
                className={classes.sticker}
                alt="sticker"
                src="/images/footer/sticker3.png"
              />
              <Grid
                item
                xs={12}
                className={clsx(classes.legalSection, classes.desktopOnly)}
              >
                <Typography variant="caption" align="center">
                  {'© '}
                  {thisyear}{' '}
                  <Link
                    color="inherit"
                    href="https://www.h-l.vc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Save Your City.
                  </Link>{' '}
                  Rights Reserved
                  {'.'}{' '}
                  <Link
                    color="inherit"
                    href="/terms"
                    className={classes.lawyerLinks}
                  >
                    Terms
                  </Link>
                  <span className={classes.verticalLine} />
                  <Link
                    color="inherit"
                    href="/privacy"
                    className={classes.lawyerLinks}
                  >
                    Privacy
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </div>
          <span className={classes.spacer} />
          <div>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {!router.pathname.includes('/donate') && (
                  <Link
                    className={clsx(classes.linkButtons, 'yellow')}
                    href="/donate"
                  >
                    Donate
                  </Link>
                )}
              </Grid>
              <Grid item xs={12}>
                {!router.pathname.includes('/nominate') && (
                  <Link className={classes.linkButtons} href="/nominate">
                    Nominate a Business
                  </Link>
                )}
              </Grid>
              <Grid xs={12} className={classes.caption}>
                <Typography variant="caption">
                  100% of donations go directly to the small businesses of your
                  choice
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.socialGroup}>
              <Typography className={classes.socialText}>Follow Us:</Typography>
              <Link
                href="https://www.instagram.com/saveyourcity.nyc/"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialIcon}
                  alt="social-icon"
                  src="/images/social/Asset_IG.png"
                />
              </Link>
              <Link
                href="https://www.facebook.com/saveyourcity.nyc"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.socialIcon}
                  alt="social-icon"
                  src="/images/social/Asset_FB.png"
                />
              </Link>
            </Grid>
          </div>
          <Grid
            item
            xs={12}
            className={clsx(classes.legalSection, classes.mobileOnly)}
          >
            <Typography variant="caption" align="center">
              {'© '}
              {thisyear}{' '}
              <Link
                color="inherit"
                href="https://www.h-l.vc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Save Your City.
              </Link>{' '}
              Rights Reserved
              {'.'}{' '}
              <Link
                color="inherit"
                href="/terms"
                className={classes.lawyerLinks}
              >
                Terms
              </Link>
              <span className={classes.verticalLine} />
              <Link
                color="inherit"
                href="/privacy"
                className={classes.lawyerLinks}
              >
                Privacy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
